
import React, { useCallback } from 'react';
import { Switch } from 'devextreme-react';
const ColumnSwitchBox = (props) => {
    const value = props.data.value ? props.data.value : 0;
    console.log("ColumnSwitchBox", props);

    const onValueChanged = useCallback(
        (e) => {
            console.log("ColumnSwitchBox onValueChanged", e);
            props.data.setValue(e.value);
        },
        [props],
    );

    return (
        <Switch defaultValue={value} onValueChanged={onValueChanged} switchedOnText='Yes' switchedOffText='No' />
    );
};
export default ColumnSwitchBox;