import api from "api";

import { useEffect, useState } from "react";
import { Link, matchRoutes, useParams } from "react-router-dom";


const HeaderCrums = (props) => {

    const location = window.location.href;
    const pathname = window.location.pathname;
    const routes = api.getItem("routes");
    console.log("HeaderCrums", props,routes,location);
    
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const getCurrentPath = (routes, pathname) => {
        var out = undefined;

        if (!routes) return;
        const pathx = routes.map((item) => {
            // console.log(item.path);
            const itemPath = item.path;
            const routes = [{ "path": itemPath }];
            const routexx = matchRoutes(routes, pathname);
            const pathx = routexx ? routexx[0].route.path : undefined;
            console.log("getCurrentPath item,routex, route: ", itemPath, pathx);
            return pathx;
        });

        console.log("getCurrentPath pathx: ", pathx);
        if (pathx != undefined) {
            for (var i = 0; i < pathx.length; i++) {
                if (pathx[i] != undefined) {
                    out = pathx[i];
                    break;
                }
            }
        }
        return out;

    }

    const generateBreadcrumbs = () => {

        var breadcrumbs = new Array(0);
        var currentPath = getCurrentPath(routes, pathname);
        // console.log("breadcrumbs", location,pathname, currentPath,routes);

        const paths = currentPath ? currentPath.split('/').slice(1) : [];
        const locs = pathname ? pathname.split('/').slice(0) : [];
        
        paths.map((p, index) => {
            if (p != "") {
                console.log("p", p, index, locs.slice(0, index + 2).join('/'));
                breadcrumbs.push({
                    title: `${p}`,
                    link: `${locs.slice(0, index + 2).join('/')}`,
                });
                setBreadcrumbs(breadcrumbs);
            }
        });

        setBreadcrumbs(breadcrumbs);

        // then let's loop over the breadcrumbs, and check if we have a value that should be convered to a name
        breadcrumbs.map((p, index) => {
            const pTitle = p.title;
            console.log("breadcrumbs", p, pTitle, index);
            if (pTitle.left(1) == ":") {
                const itemName = pTitle.replace(/:/g, '');
                const locItem = locs[index + 1];
                const endpoint = "admin/lookups/breadcrumbs?item_name=" + itemName + "&item_value=" + locItem + "";
                console.log("breadcrumbs response", endpoint);
                api.get(endpoint, null, (response => {
                    const item_caption = response.data.item_caption;
                    const newCrumb = {
                        title: `${item_caption}`,
                        link: `${p.link}`,
                    }
                    
                    const updatedBreadcrumbs = breadcrumbs.map((item)=>{
                        if (item.link == newCrumb.link) {
                            return newCrumb;
                        } else {
                            return item;
                        }
                    });

                    // breadcrumbs[index].title = item_caption;
                    setBreadcrumbs(updatedBreadcrumbs);
                }));
            }
        });
        setBreadcrumbs(breadcrumbs);
    }
     
    useEffect(() => {
        console.log("????",breadcrumbs);
        generateBreadcrumbs();
    }, [location]);

    return (
        <div className="ml-[6px]">
            <div className="h-6 pt-1">
                <span
                    className="text-sm font-normal text-navy-700 hover:none dark:text-white dark:hover:text-white"
                    href=" ">Home
                </span>
                <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
                    /
                </span>

                {breadcrumbs.map((b, index) =>
                    <span key={index}>
                        {(breadcrumbs.length - 1) < (index + 1) ?
                            <>
                                <span
                                    className="text-lg font-normal text-navy-700 hover:none dark:text-white dark:hover:text-white"
                                    href=" ">{b.title}
                                </span>
                            </>
                            :
                            <>
                                <Link
                                    className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
                                    to={b.link} >
                                    {b.title}
                                </Link>
                                <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
                                    /
                                </span>
                            </>
                        }
                    </span>
                )}
            </div>
        </div>
    )
}

export default HeaderCrums;