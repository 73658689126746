
// import MainContentComponent from './MainContentComponent.js';
import React, { Component } from 'react';
// import DataGridComponent from './DataGridComponent.js';
// import ImportComponent from './ImportComponent.js';

class ErrorComponent extends Component {
    constructor(props) {
        super(props);
        this.item = this.props.item;
        console.log("ErrorComponent",props);
    }

    render() {
        return <div>invalid component <code>{this.props.tag}</code> xx </div>
    }
}

export default ErrorComponent;