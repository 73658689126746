
import React, { useCallback } from 'react';
import TagBox from 'devextreme-react/tag-box';
// import { Column } from 'devextreme-react/data-grid';

const nameLabel = { 'aria-label': 'Name' };
const ColumnTagBox = (props) => {
    function getJson(val) {
        var str = val;
        try {
            str = JSON.parse(val);
        } catch (e) {
            return str;
        }
        return str;
    }

    console.log("ColumnTagBox", props);

    const dataSource = props.data.column.lookup ? props.data.column.lookup.dataSource : [];
    const caption = props.data.column.caption ? props.data.column.caption : "xxx";
    const displayExpr = "item_value";
    const valueExpr = "item_id";
    const values = props.data.value ? getJson(props.data.value) : [];
    

    const onValueChanged = useCallback(
        (e) => {
            // get the field name, and then we convert the array value into and obj Array of [{field:value},{field:value}....]
            const dataField = props.data.column.dataField;

            // dataField = object|field, we need to split on | and take the field value to push
            // e.g. dataField = usecases|usecase_id, the resulting object array would be usecases:[{usecase_id:1},{usecase_id:2}...]
            const fieldx = dataField.split("|")[1] ? dataField.split("|")[1] : dataField;

            var newVal = [];
            for (var i = 0; i < e.value.length; i++) {
                var item = {};
                item[fieldx] = e.value[i];
                newVal.push(item);
            }
            props.data.setValue(newVal);
        },
        [props],
    );

    const onSelectionChanged = useCallback(() => {
        props.data.component.updateDimensions();
    }, [props]);

    return (
        
        <TagBox
            dataSource={dataSource}
            defaultValue={values}
            valueExpr={valueExpr}
            displayExpr={displayExpr}
            showSelectionControls={true}
            maxDisplayedTags={3}
            inputAttr={nameLabel}
            showMultiTagOnly={false}
            applyValueMode="useButtons"
            searchEnabled={true}
            onValueChanged={onValueChanged}
            onSelectionChanged={onSelectionChanged}
        />

    );
};
export default ColumnTagBox;