// import axios from "axios";
import authImg from "assets/img/auth/jonathan-borba--aG1-dZgiTw-unsplash.jpg";
// import authImg from "assets/img/auth/lars-kienle-r3pIy-3Xgmg-unsplash.jpg";
import Footer from "components/footer/FooterAuthDefault";
// import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form"
import api from "api";
// import { predefinedPaths } from "utils/consts";

export default function SignIn() {

  const { register, handleSubmit } = useForm()
  // const onSubmit = (data, e) => console.log(data, e)
  const onError = (errors, e) => console.log(errors, e)

  const navigate = useNavigate();
  const onSubmit = (data, e) => {
    e.preventDefault();

    // const endpoint = "/admin/signin";
    const endpoint = window._env_.REACT_APP_API_URL_LOGIN;
    api.post(endpoint, {
      login: data.username,
      password: data.password
    }, (response) => {
      console.log("api.login", response.data);
      api.setAccessToken(response.data);
      api.get("/me", null, (response) => {
        api.setCurrentUser(response.data);
        console.log("api.me", response.data);
        navigate("/");
      });
    });
  };


  return (

    <div className="p-5 flex rounded-2xl shadow-lg max-w-5xl bg-gray-50">
      <div className="md:w-1/2 px-5">
        <img className='max-w-sm p-5 mt-2' src="https://www.gsma.com/services/wp-content/plugins/plugin_header_overload/images/GSMA-Logo-Black-RGB.svg" alt="GSMA logo"></img>
        <h2 className="text-2xl font-bold text-[#002D74]">GSMA Call Check {window._env_.REACT_APP_CAPTION} Login</h2>
        <form className="mt-6 mb-auto" action="#" method="POST" onSubmit={handleSubmit(onSubmit, onError)} name="loginFrm">
          <div>
            <label className="block text-gray-700">Login</label>
            <input type="email" name="username" id="username" {...register("username")} placeholder="Enter Email Address" className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-navy-500 focus:bg-white focus:outline-none" required />
          </div>

          <div className="mt-4">
            <label className="block text-gray-700">Password</label>
            <input type="password" name="password" id="password" {...register("password")} placeholder="Enter Password" minLength="6" className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-navy-500
                  focus:bg-white focus:outline-none" required />
          </div>

          <button type="submit" className={window._env_.REACT_APP_LOGIN_COLOR}>Log In</button>
        </form>

        <div className="text-sm flex justify-between items-center mt-3 h-10">
          <Footer />
        </div>
      </div>

      <div className="w-1/2 md:block hidden ">
        <img src={authImg} className="rounded-2xl" alt="page img" />
      </div>

    </div>

  );
}
