
import { useParams } from "react-router-dom";
// import Banner from "./components/Banner";
// import General from "./components/General";
// import Notification from "./components/Notification";
// import Project from "./components/Project";
// import Storage from "./components/Storage";
// import Upload from "./components/Upload";

const ClientProfileView = () => {
  const {client_id} = useParams();
  return (
    <div>
      <h1>we will client_id <strong>{client_id}</strong></h1>
    </div>
  );
};

export default ClientProfileView;

// 