import React, { useCallback, useReducer, useState } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import api from "api";
import * as utils from '../../utils/utils.js'
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';

export default function MyPopupDialog(props) {
    var items = props.items;
    var consentItem = undefined;
    const [consentContent, setConsentContent] = useState();
    const [consentMessage, setConsentMessage] = useState("");
    const [consentTitle, setConsentTitle] = useState("");
    // const [consentItem, setConsentItem] = useState();
    const [popupVisible, setPopupVisible] = useState(false);
    const { ...urlParams } = useParams();

    // this will submit an API request for the consent and then close the window
    const acceptAndCloseFn = useCallback((e) => {
        const data = consentItem;
        const endpoint = '/me/consents/{id}';
        const resolvedEndPoint = utils.resolveEndPointStr(endpoint, consentItem, urlParams);
        api.method("PUT", resolvedEndPoint, data, (response) => {
            console.log("executeCommand1", response.data);
            console.log("executeCommand2", data.id, items);
            items = items.filter((x) => x.id != data.id);
            if (items.length > 0) {
                console.log("executeCommand3", data.id, items);
                setConsentContent(items);
                console.log("executeCommand4", items);
                setContent();
            } else {
                const msg = "thank you!";
                api.showMessage(msg,'success','top center');
                hideInfo();
            }
        });
    }, [setPopupVisible]);

    // if they clsoe it, then we dont save, and it will popup again
    const hideInfo = useCallback(() => {
        setPopupVisible(false);
    }, [setPopupVisible]);


    const getAcceptButtonOptions = useCallback(
        () => ({
            icon: 'check',
            text: 'OK',
            value: true,
            onClick: acceptAndCloseFn,
        }),
        [acceptAndCloseFn],
    );

    const setContent = function () {
        console.log("setContent", items);
        if (items != undefined) {
            items.map((item) => {
                if (item.is_accepted == 0) {
                    // setConsentItem(item);
                    consentItem = item;
                    const clean = DOMPurify.sanitize(item.consent_message);
                    setConsentMessage(clean);
                    setConsentTitle(item.consent_title);
                    setPopupVisible(true);
                    return;
                }
                setPopupVisible(false);
            });

        };
    }

    React.useEffect(() => {
        setConsentContent();
    }, [consentContent]);

    React.useEffect(() => {
        setContent();
    }, [items]);

    // const defaultOptions = {
    //     // ALLOWED_TAGS: [ 'b', 'i', 'u','br','p','em', 'strong', 'a' ], 
    //     // ALLOWED_ATTR: ['href']
    //   };
      
    //   const sanitize = (dirty, options) => ({
    //     __html: DOMPurify.sanitize(
    //       dirty, 
    //       { ...defaultOptions, ...options }
    //     )
    //   });
      
    //   const SanitizeHTML = ({ html, options }) => (
    //     <div dangerouslySetInnerHTML={sanitize(html, options)} />
    //   );

    return (
        <Popup
            container={props.container}
            visible={popupVisible}
            onHiding={hideInfo}
            hideOnOutsideClick={false}
            showCloseButton={false}
            hideOnParentScroll={false}
            dragEnabled={false}
            showTitle={true}
            title={consentTitle}>
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={getAcceptButtonOptions()}
            />
            <div dangerouslySetInnerHTML={{ __html: consentMessage }} />

        </Popup>
    );
}
