import axios from "axios";
import { loadProgressBar } from "axios-progress-bar";
import { endpoints } from "utils/consts";
import { ProcessPath, isEmpty } from "utils/utils";
import Notify from 'devextreme/ui/notify';
import { predefinedPaths } from "utils/consts";
// import { useNavigate } from "react-router-dom";
// import { util } from "prettier";

// const storage = localStorage
const storage = sessionStorage

// const adapter = createCacheAdapter({
//   defaultTTL: 5000, // one second
// });



class api {
  //  endpoint_admin_signout = "/admin/signout"; //logout
  endpoint_admin_signout = window._env_.REACT_APP_API_URL_LOGOUT;
  constructor() {
    let service = axios.create({});
    // service.adapter=adapter;
    // const CancelToken = axios.CancelToken;
    service.defaults.baseURL = endpoints.API_URL //|| "http://127.0.0.1:9091/api/v2/";
    service.defaults.headers.common['Content-Type'] = 'application/json';
    service.defaults.headers.post['Accept'] = 'application/json';
    service.defaults.withCredentials = true;
    service.interceptors.request.use(function (config) {
      const token = JSON.parse(storage.getItem('token'));
      const access_token = token ? token.access_token : null;
      config.headers.Authorization = access_token ? 'Bearer ' + access_token : '';

      return config;
    });

    service.interceptors.response.use(this.handleSuccessResponse, this.handleErrorResponse);

    this.service = service;
    // let types = { error: 'error', info: 'info', success: 'success', warning: 'warning' };

    // this is to show progress bar when an API request is made
    loadProgressBar({
      minimum: 0.08,
      easing: 'linear',
      positionUsing: '',
      speed: 200,
      trickle: true,
      trickleSpeed: 200,
      showSpinner: true,
      barSelector: '[role="bar"]',
      spinnerSelector: '[role="spinner"]',
      parent: 'body',
      template: '<div className="bar" role="bar"><div className="peg"></div></div><div className="spinner" role="spinner"><div className="spinner-icon"></div></div>'
    }, this.service);

  }

  showMessage(msgStr, msgType,pos,dir) {
    const position = pos? pos: 'top right';
    const direction = dir?dir:'down-push';

    Notify({
      closeOnClick: true,
      message: msgStr,
      height: "auto",
      width: "auto",
      minWidth: 150,
      type: msgType,
      displayTime: 6000,
      animation: {
        show: {
          type: 'fade', duration: 400, from: 0, to: 1,
        },
        hide: { type: 'fade', duration: 40, to: 0 },
      },
    }, {
      position,
      direction,
    });
  }

  // this is the function to set the user object when login
  setItemData(key, data) {
    if (data != undefined) {
      storage.setItem(key, JSON.stringify(data));
    }
  }

  // this is the function to get user object if logged in
  getItemData(key) {
    var data = storage.getItem(key)
    if (isEmpty(data)) { return null; }
    else { return JSON.parse(data); }
  }

  delItem(key) {
    if (key != undefined) {
      storage.removeItem(key);
    }
  }


  // this is the function to set the user object when login
  setAccessToken(token) {
    storage.setItem("token", JSON.stringify(token));
  }

  // this is the function to get user object if logged in
  getAccessToken() {
    var token = storage.getItem('token')
    if (isEmpty(token)) { return []; }
    else { return JSON.parse(token); }
  }

  setCurrentUser(user) {
    storage.setItem("user", JSON.stringify(user));
  }



  // this is the function to get user object if logged in
  getCurrentUser() {
    var user = storage.getItem('user');
    if (isEmpty(user)) {
      this.get("/me", null, (response) => {
        //this is to set the access token
        console.log("getCurrentUser", response)
        const user = response.data;
        api.setCurrentUser(user);
        return JSON.parse(user);
      })
      return JSON.parse(user);
    }
    else { return JSON.parse(user); }
  }

  handleSuccessResponse(response) {
    return response;
  }

  handleErrorResponse = (error) => {
    // const navigate = useNavigate();
    if (error && error.response) {
      const respErrStatus = error.response.status;

      if (respErrStatus <= 0) {
        // no connection to server or unknown error 
        msg = error.message;
        this.showMessage(msg, 'error');
        return Promise.reject(error);
      }

      const resp = error.response.data;
      console.log("handleErrorResponse", error, resp);

      var msg = "Error " + error.response.status + ":" + resp.meta.code + "# " + resp.meta.message;
      if (error.response.status >= 400 && error.response.status <= 499) {
        if (error.response.status == 401) {
          //unauthrized
          // console.log("xxx",msg);
          // check if user is empty
          if (isEmpty(this.getAccessToken())) {
            // debug - uncomment below
            //we need to go to login
            this.logout(this.endpoint_admin_signout);
            window.location = window.location.protocol + "//" + window.location.host + predefinedPaths.LOGIN;
            // navigate(predefinedPaths.LOGIN);
            return ;
          }

          this.showMessage(msg, 'error');
        } if (error.response.status == 404) {
          // this.showMessage(msg, 'info');
          if (error.response.config.method != 'get') {
            this.showMessage(msg, 'warning');
          }
          return error.response;
        } else {
          this.showMessage(msg, 'warning');
        }

      } else if (error.response.status >= 500 && error.response.status <= 599) {
        this.showMessage(msg, 'error');
      } else {
        msg = error.response.status + ": " + error.response.statusText;
        this.showMessage(msg, 'error');
      }
    } else {
      // comment out
      // window.location = window.location.protocol + "//" + window.location.host + predefinedPaths.LOGIN;
    }
    return Promise.reject(error)
  }

  // these are the HTTP request functions (Get, post, put ...etc)

  // axiosTest =  (url) => {
  //   const data = [];
  //   try {
  //     if (!isEmpty(url)){
  //     // use data destructuring to get data from the promise object
  //     const { data: response } =  this.service.get(url); 

  //     data.push(...response.data);
  //     console.log("get res",response,data);
  //     return data;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  get(path, params, callback, errCallback) {
    return this.service.get(path, { params: params }).then(
      (response) => {
        if (typeof callback === "function") {
          console.log("callback is function",callback);
          callback(response.data);
        }
      }
    ).catch((error) => {
      if (typeof errCallback === "function") {
        errCallback(error.response);
      } else {
        this.handleErrorResponse(error.response)
      }
    })
  }

  put(path, payload, callback) {
    return this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.data));
  }

  post(path, payload, callback) {
    return this.service.request({
      method: 'POST',
      url: path,
      data: payload,
    }).then((response) => callback(response.data))
  }

  delete(path, payload, callback) {
    return this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.data));
  }

  method(method, path, payload, callback) {
    return this.service.request({
      method: method,
      url: path,
      data: payload,
    }).then((response) => callback(response.data));
  }

  // // auth methods
  register(login, email, phone, password) {
    //   this.post("auth/signup", {
    //     login,
    //     email,
    //     phone,
    //     password
    //   }, (response) => {
    //     if (response.data.access_token) {
    //       storage.setItem("user", JSON.stringify(response.data));
    //     }
    //   })
  }

  logout(endpoint) {
    this.post(endpoint, {}, (response) => {
      console.log(response);
      storage.clear();
      // storage.removeItem("user");
      // storage.removeItem(endpoints.menu);
      // storage.removeItem(endpoints.routes);

    })
  }



  setItem(key, value) {
    storage.setItem(key, JSON.stringify(value));
  }

  // this is the function to get user object if logged in
  getItem(key) {
    var value = storage.getItem(key);
    return JSON.parse(value);
  }

}

export default new api;