import React from "react";
// import Dropdown from "components/dropdown";
// import { FiAlignJustify } from "react-icons/fi";
// import { Link } from "react-router-dom";
// import navbarimage from "assets/img/layout/Navbar.png";
// import { BsArrowBarUp } from "react-icons/bs";
// import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
// import {
//   IoMdNotificationsOutline,
//   IoMdInformationCircleOutline,
// } from "react-icons/io";
// import avatar from "assets/img/avatars/avatar4.jpg";

const Footer = (props) => {

  const [darkmode, setDarkmode] = React.useState(false);

  return (
    <div className="flex w-full flex-col items-center justify-between px-0 pb-0 pt-0 lg:px-8 xl:flex-row">
      {/* <h5 className="mb-1 text-center text-gray-600 sm:!mb-0"> */}
        <p className="mb-1 text-left text-xs text-gray-600">
          <span className="p-1">© Copyright {1900 + new Date().getYear()} <a href="https://www.gsma.com" target="_blank">GSM Association</a>. </span>
          <span className="p-1">Powered by <a href="https://www.oculeus.com" target="_blank">Oculeus GmbH</a>.</span>
        </p>
      {/* </h5> */}
      <div>
        <ul className="flex flex-wrap items-center sm:flex-nowrap gap-2">
          <li>
       {/* switch dark/light mode */}
       <div
              className="cursor-pointer text-gray-600"
              onClick={() => {
                if (darkmode) {
                  document.body.classList.remove("dark");
                  setDarkmode(false);
                } else {
                  document.body.classList.add("dark");
                  setDarkmode(true);
                }
              }}
            >
            </div>           
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
