
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "routes.js";
import { predefinedPaths } from "utils/consts";

export default function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      // if (prop.layout === "/auth") {
      return (
        <Route path={`/${prop.path}`} element={prop.component} key={key} /> //this is for auth
      );
      // } else {
      // return null;
      // }
    });
  };
  document.documentElement.dir = "ltr";
  return (

    <main className={`mx-auto min-h-screen max-h-screen`}>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <section className=" min-h-screen flex items-center justify-center">
          <Routes>
            {getRoutes(routes)}
            <Route
              path="/"
              element={<Navigate to={predefinedPaths.LOGIN || "auth/sign-in"} replace />}
            />
          </Routes>
        </section>

      </div>
    </main>

  );
}
