/*eslint-disable*/
import React from "react";
export default function Footer() {
  return (
    <div className="z-[5] mx-auto flex w-full max-w-screen-sm flex-col items-center justify-between px-[20px] pb-4 lg:mb-6 lg:max-w-[100%] lg:flex-row xl:mb-2 xl:w-[1310px] xl:pb-6">
              <p className="mb-1 text-left text-xs text-gray-600">
          <span className="p-1">© Copyright {1900 + new Date().getYear()} <a href="https://www.gsma.com" target="_blank">GSM Association</a>. </span>
          <span className="p-1">Powered by <a href="https://www.oculeus.com" target="_blank">Oculeus GmbH</a>.</span>
        </p>
    </div>
  );
}
